//-------------Importar librerias----------------------------
import React, { useState, useRef, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
// -----------Importar Componentes de Terceros---------------------
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';


// ----------------Importar Archivos Estaticos--------------------------
import '../css/personaliza.css'
import Entierro from '../../../assets/Imagenes/Sets/selecciona_tumba.png'
import Cremacion from '../../../assets/Imagenes/Sets/selecciona_cremacion.png'

// -----------Importar Componentes Personalizados------------------
import HorizontalLinearStepper from "../../../components/Stepper";
import StepPersonalizaSet from './Step_PersonalizaSet';
import ModalBaseMui from '../../../components/Modal_Mui';
import ContentModalAgregarCarrito from './ContentModalAgregarCarrito';
import StepExtra from './Step_Extra';
import StepDatosPersonales from './Step_DatosPersonales';
import StepPago from './Step_Pago';
import StepConfirmacion from './Step_Confirmacion';
import CarritoWeb from '../../../components/Carrito_Web';
import CarritoMovil from '../../../components/Carrito_Movil';
import NotaVenta from '../../../components/Nota_Venta';
import EmailConfirmacion from '../../../components/Email_Confirmacion';


// ------------------------Valores Constantes----------------------------
import StyleDragArea from '../css/button';
import { DensityLarge, GitHub } from '@mui/icons-material';


const PersonalizaSets = ({EnviarMensaje,DataDetalleSetsFuenerarios,DetalleSetsFuenerarios, CallNumber, OnClickBarraProgreso, OcultarFoother, TotalServicioDespedida, Step_Pago, Step_Datos, Step_Extra, Step_PersonalizaSet, HandleNext, HandleBack, StepLenght, ListHeadersStep, OnclickReturn, SetSeleccionado, DataSets, FormActualizar, SwitchHandleChange, SubTotal, SubTotalExtra, Token }) => {
  const SetNombreSeleccionado = DataSets.find((element) => (element.id === SetSeleccionado)).nombre
  const ListTypo = [{ id: 1, Nombre: 'Sepultura', Imagen: Entierro }, { id: 2, Nombre: 'Cremacion', Imagen: Cremacion }];
  const [Desplegar, setDesplegar] = useState(false);

  const [carritoFixed, setCarritoFixed] = useState(false);
  const [PdfConfirmacionPago, setPdfConfirmacionPago] = useState('')
  const [scrollLimit, setScrollLimit] = useState(0);

  const RefContenido = useRef(null);


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setCarritoFixed(!entry.isIntersecting);
      },
      {
        threshold: 0,
        rootMargin: '0px 0px -100% 0px',
      }
    );

   


    if (RefContenido.current) {
      observer.observe(RefContenido.current);
    }

    return () => {
      if (RefContenido.current && StepLenght < 2) {
        observer.unobserve(RefContenido.current);
      }
    };
  }, []);


  useEffect(() => {
    // Calcular el límite dinámico basado en la altura total menos 150px
    const updateScrollLimit = () => {
      const totalHeight = document.documentElement.scrollHeight; // Altura total del documento
      setScrollLimit(totalHeight - 1000);
    };

    // Actualizar el límite al cargar la página o redimensionarla
    updateScrollLimit();
    window.addEventListener('resize', updateScrollLimit);

    return () => {
      // Limpiar el evento de redimensionamiento
      window.removeEventListener('resize', updateScrollLimit);
    };
  }, [document.documentElement.scrollHeight]);

  useEffect(() => {
    const preventScroll = (e) => {
      if (!carritoFixed && StepLenght < 2  && window.scrollY >= scrollLimit && e.deltaY > 0) {
        e.preventDefault(); // Evitar que baje más allá del límite
        window.scrollTo(0, scrollLimit); // Asegurarse de mantener al usuario en el límite
      }
    };

    if (!carritoFixed && StepLenght < 2 ) {
      // Escuchar el evento wheel y touchmove si el límite está activo
      window.addEventListener('wheel', preventScroll, { passive: false });
      window.addEventListener('touchmove', preventScroll, { passive: false });
    }

    return () => {
      // Limpiar los eventos al desmontar o desactivar el límite
      window.removeEventListener('wheel', preventScroll);
      window.removeEventListener('touchmove', preventScroll);
    };
  }, [carritoFixed, scrollLimit]);

  useEffect(() => {
    SwitchHandleChange('form');
    OcultarFoother();
  }, []);

  const formatearComoMonedaMXN = (cantidad) => {
    const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    });
    return formatoMonedaMXN.format(cantidad);
  };

  const navigate = useNavigate()
  const handleClick = (link) => {
    navigate(link)
  }



  const generatePdf = () => {
    const input = document.getElementById('imagen2');
    let maxWidth = 210; // Ancho de la página en mm (A4)
    const maxHeight = 297; // Largo de la página en mm (A4)

    html2canvas(input)
      .then((canvas) => {
        let imgWidth = canvas.width;
        let imgHeight = canvas.height;

        // Redimensionar la imagen si el alto es mayor que el largo de una hoja A4
        if (imgHeight > maxHeight) {
          const ratio = maxHeight / imgHeight;
          imgWidth *= ratio;
          imgHeight *= ratio;
          maxWidth = imgWidth < 210 ? imgWidth : 210
        }

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();

        // Establecer márgenes en mm (A4)
        const marginLeft = 0;
        const marginTop = 0;
        const marginRight = 0;
        const marginBottom = 0;

     
        pdf.addImage(imgData, 'PNG', marginLeft, marginTop, maxWidth - marginLeft - marginRight, maxHeight - marginTop - marginBottom);
        pdf.setProperties({
          title: "MyTitle.pdf"
        });
        setPdfConfirmacionPago(pdf)
        window.open(pdf.output('bloburl'));


      })
      .catch((error) => {
        console.error('Error generating PDF:', error);
      });
  };



  return (
    <div className="  mt-5" >
      <Grid style={{ marginRight: '0px', marginLeft: '0px' }} container >
        <Grid xs={12} md={StepLenght > 3 ? 7 : 8} >
          <div className="row">
            <div className="col-sm-12">
              <Typography mb='0px' variant='h1' sx={{ fontSize: '40px !important', fontWeight: '600 !important' }} className="titulo"> {ListHeadersStep[StepLenght].Title}</Typography>
              <Typography sx={{ fontSize: '15px', color: '#777777' }} id="tipoVelacion">{SetNombreSeleccionado}</Typography>
              {StepLenght > 2
                ? <></>
                :
                <StyleDragArea><a type='button' onClick={StepLenght === 0 ? OnclickReturn : () => (HandleBack('StepLenght'))} className="btn btnRosa" style={{ fontSize: '12px', color: 'white' }} >Atras</a></StyleDragArea>
              }
            </div>
          </div>
        </Grid>
        <Grid xs={12} md={StepLenght > 3 ? 5 : 4}   >
          <Box className="col-sm-12 mt-2" sx={{ display: { xs: 'none', md: 'block' } }}>
            <HorizontalLinearStepper OnClickBarraProgreso={OnClickBarraProgreso} FontSize={'0.85rem'} Steps={ListHeadersStep} VisualOnly={Step_Pago.FormPago.KeyCliente === '' ? false : true} NoLabel={false} ActiveStep={StepLenght} />
          </Box>
        </Grid>
        <Grid xs={12} md={StepLenght > 3 ? 7 : 9} sx={{ marginTop: { xs: '0px', sm: '24px' } }}  >
          <Box className="col-sm-12" sx={{ marginTop: '5%', marginBottom: '2%', display: { xs: 'block', md: 'none' } }}>
            <HorizontalLinearStepper OnClickBarraProgreso={OnClickBarraProgreso} FontSize={'0.85rem'} Steps={ListHeadersStep} VisualOnly={Step_Pago.FormPago.PedidoCliente === '' ? false : true} NoLabel={false} ActiveStep={StepLenght} />
          </Box>

          <div id="contenedorFijo" ref={RefContenido}>

            {
              [
                <StepPersonalizaSet TypoDespedida={FormActualizar.TipoDespedida} ListTypo={ListTypo} ListGrupos={Step_PersonalizaSet.ListGrupos} ListProductos={Step_PersonalizaSet.ListProductos} ListCarrito={Step_PersonalizaSet.ListCarrito} SeleccionarDespedida={Step_PersonalizaSet.SeleccionarDespedida} OnClickShowAgregarCarrito={Step_PersonalizaSet.OnClickShowAgregarCarrito} Style={StyleDragArea} />,
                <StepExtra AgregarCarritoExtra={Step_Extra.AgregarCarritoExtra} OnClickShowAgregarCarritoExtra={Step_Extra.OnClickShowAgregarCarritoExtra} CargarProductosExtras={Step_Extra.CargarProductosExtras} ListProductosExtras={Step_Extra.ListProductosExtras} ListGruposExtras={Step_Extra.ListGruposExtras} ListCarritoExtras={Step_Extra.ListCarritoExtras} Style={StyleDragArea} />,
                <StepDatosPersonales Token={Token} OnClickGenerarCliente={Step_Datos.OnClickGenerarCliente} HandleChangeSingle={Step_Pago.HandleChangeSingle} HandleNext={() => (HandleNext('StepLenght'))} SwitchHandleChange={SwitchHandleChange} OnChangeCobertura={Step_Datos.OnChangeCobertura} FormFinado={Step_Datos.FormFinado} FormResponsable={Step_Datos.FormResponsable} OnChange={Step_Datos.OnChange} ListEstados={Step_Datos.ListEstados} ListMunicipios={Step_Datos.ListMunicipios} ListColonias={Step_Datos.ListColonias} Style={StyleDragArea} />,
                <StepPago EnviarMensaje={EnviarMensaje} Total={SubTotal + SubTotalExtra} OnClickHandleNext={HandleNext} Token={Token} FormResponsable={Step_Pago.FormResponsable} FormFinado={Step_Pago.FormFinado} OnClickGuardar={Step_Pago.OnClickGuardar} OnClickValidarCupon={Step_Pago.OnClickValidarCupon} DataValidarCupon={Step_Pago.DataValidarCupon} TotalServicioDespedida={TotalServicioDespedida} TipoServicio={SetNombreSeleccionado} TipoDespedida={FormActualizar.TipoDespedida === '' ? '' : ListTypo.find((element) => (element.id === FormActualizar.TipoDespedida)).Nombre} CorreoResponsable={Step_Datos.FormResponsable.Email} SubTotalExtra={SubTotalExtra} SubTotal={SubTotal} ListCarritoExtra={Step_Extra.ListCarritoExtras} ListProductos={Step_PersonalizaSet.ListProductos} ListCarrito={Step_PersonalizaSet.ListCarrito} HandleChangeSingle={Step_Pago.HandleChangeSingle} FormPago={Step_Pago.FormPago} OnChange={Step_Pago.OnChange} Style={StyleDragArea} SwitchHandleChange={SwitchHandleChange} />,
                <StepConfirmacion Estado={Step_Datos.ListEstados.find(item => item.estadoId.trim() === Step_Pago.FormFinado.Estado)} Municipio={Step_Datos.ListMunicipios.find(item => item.municipioId.trim() === Step_Pago.FormFinado.Municipio)} Colonia={Step_Datos.ListColonias.find(item => item.asentamientoId.trim() === Step_Pago.FormFinado.Colonia)} FormFinado={Step_Pago.FormFinado} FormPago={Step_Pago.FormPago} ListCarritoExtras={Step_Extra.ListCarritoExtras} ListProductos={Step_PersonalizaSet.ListProductos} ListCarrito={Step_PersonalizaSet.ListCarrito} TotalServicioDespedida={TotalServicioDespedida} SetNombreSeleccionado={SetNombreSeleccionado} ListTypo={ListTypo} TipoDespedida={FormActualizar.TipoDespedida} SubTotalExtra={SubTotalExtra} SubTotal={SubTotal} formatearComoMonedaMXN={formatearComoMonedaMXN} CallNumber={CallNumber} SwitchHandleChange={SwitchHandleChange} OnClickHandleNext={HandleNext} PedidoCliente={Step_Pago.FormPago.PedidoCliente} />
              ][StepLenght]
            }
          </div>

        </Grid>




        <Grid sx={{ padding: '0px 0px 0px 20px' }} xs={12} md={StepLenght > 3 ? 5 : 3} >

          {
            StepLenght > 3
              ?
             // <></>
               <NotaVenta HorizontalLinearStepper={HorizontalLinearStepper} StyleDragArea={StyleDragArea} ListHeadersStep={ListHeadersStep} StepLenght={StepLenght} handleClick={handleClick} formatearComoMonedaMXN={formatearComoMonedaMXN} Step_Pago={Step_Pago} SubTotalExtra={SubTotalExtra} SubTotal={SubTotal} Step_Extra={Step_Extra} Step_PersonalizaSet={Step_PersonalizaSet} TotalServicioDespedida={TotalServicioDespedida} ListTypo={ListTypo} FormActualizar={FormActualizar} SetNombreSeleccionado={SetNombreSeleccionado} />
              :
              <Box sx={{ marginTop: '0px', transition: 'margin-top 0.1s' }} >

                {/* Carrito version Web */}
                <Box sx={{ display: { xs: 'none', md: 'block' }, position: StepLenght > 1 ? 'static' : carritoFixed ? 'static' : 'fixed', zIndex: 1200, top: 0, }}>

                  <CarritoWeb CarritoFixed={StepLenght > 1 ? true : carritoFixed} StyleDragArea={StyleDragArea} FormatearComoMonedaMXN={formatearComoMonedaMXN} SetNombreSeleccionado={SetNombreSeleccionado} FormActualizar={FormActualizar} Step_PersonalizaSet={Step_PersonalizaSet} Step_Extra={Step_Extra} ListTypo={ListTypo} Step_Pago={Step_Pago} SubTotal={SubTotal} SubTotalExtra={SubTotalExtra} StepLenght={StepLenght} HandleNext={HandleNext} />
                </Box>

                {/* Carrito version Movil */}
                <Paper sx={{ position: 'fixed', zIndex: 100, bottom: 0, left: 0, right: 0, backgroundColor: 'white', padding: '5px', display: { xs: FormActualizar.TipoDespedida === '' ? 'none' : 'block', md: 'none' } }}>
                  <CarritoMovil StyleDragArea={StyleDragArea} FormatearComoMonedaMXN={formatearComoMonedaMXN} SetNombreSeleccionado={SetNombreSeleccionado} FormActualizar={FormActualizar} Step_PersonalizaSet={Step_PersonalizaSet} Step_Extra={Step_Extra} ListTypo={ListTypo} Step_Pago={Step_Pago} SubTotal={SubTotal} SubTotalExtra={SubTotalExtra} StepLenght={StepLenght} HandleNext={HandleNext} Desplegar={Desplegar} setDesplegar={setDesplegar} />
                </Paper>
              </Box>

          }

          {
            Step_PersonalizaSet.ShowAgregarCarrito === true
              ? <ModalBaseMui Title='' Open={Step_PersonalizaSet.ShowAgregarCarrito} FullScreen={false} OnClickClose={() => (Step_PersonalizaSet.OnClickShowAgregarCarrito(''))} FullWidth={true} MaxWidth='595px'
                ContentModal={<ContentModalAgregarCarrito Title={Step_PersonalizaSet.ProductoSelecionado.nombre} CarritoExtra={false} OnClickClose={Step_PersonalizaSet.OnClickAgregarCarrito} Styles={StyleDragArea} FormatearComoMonedaMXN={formatearComoMonedaMXN} formatearComoMonedaMXN={formatearComoMonedaMXN} ListCarrito={Step_PersonalizaSet.ListCarrito} Token={Token} ProductoSelecionado={Step_PersonalizaSet.ProductoSelecionado} OnClickAgregarCarrito={Step_PersonalizaSet.OnClickAgregarCarrito} />}
              />
              : <></>
          }
          {
            Step_Extra.ShowAgregarCarritoExtra === true
              ? <ModalBaseMui Open={Step_Extra.ShowAgregarCarritoExtra} FullScreen={false} FullWidth={true} MaxWidth='595px' OnClickClose={() => (Step_Extra.OnClickShowAgregarCarritoExtra(''))}
                ContentModal={<ContentModalAgregarCarrito ListCarritoExtra={Step_Extra.ListCarritoExtras} Title={Step_Extra.ProductoSelecionadoExtra.nombre} AgregarCarritoExtra={Step_Extra.AgregarCarritoExtra} CarritoExtra={true} Styles={StyleDragArea} FormatearComoMonedaMXN={formatearComoMonedaMXN} formatearComoMonedaMXN={formatearComoMonedaMXN} ListCarrito={Step_PersonalizaSet.ListCarrito} Token={Token} ProductoSelecionado={Step_Extra.ProductoSelecionadoExtra} />}
              />
              : <></>
          }
        </Grid>
      </Grid>

      <div style={{marginBottom:StepLenght > 1 ? '0px' : carritoFixed ? '0px' : '250px'}}></div>

      {
        StepLenght === 4 && PdfConfirmacionPago === ''
        ?
        <div >
          {
            DetalleSetsFuenerarios
            ?
            <div  style={{marginTop:'100000px'}}>
            <EmailConfirmacion OnLoad={generatePdf} Estado={DataDetalleSetsFuenerarios.Estado} Municipio={DataDetalleSetsFuenerarios.Municipio} Colonia={DataDetalleSetsFuenerarios.Colonia} FormFinado={Step_Pago.FormFinado} FormPago={Step_Pago.FormPago} ListCarritoExtras={Step_Extra.ListCarritoExtras} ListProductos={Step_PersonalizaSet.ListProductos} ListCarrito={Step_PersonalizaSet.ListCarrito} TotalServicioDespedida={TotalServicioDespedida} SetNombreSeleccionado={SetNombreSeleccionado} ListTypo={ListTypo} TipoDespedida={FormActualizar.TipoDespedida} SubTotalExtra={SubTotalExtra} SubTotal={SubTotal} formatearComoMonedaMXN={formatearComoMonedaMXN} CallNumber={CallNumber.slice(3)} FolioPedidoCliente={Step_Pago.FormPago.PedidoCliente.folio} NombreCliente={Step_Pago.FormPago.PedidoCliente.contactoCliente.nombreCompleto} Fecha={Step_Pago.FormPago.PedidoCliente.fecha} TotalPago={Step_Pago.FormPago.PedidoCliente.total} /> 

            </div>
            :
            <></>
          }
      </div>
        :
        <></>
      }
    </div>

  );
}

export default PersonalizaSets;

